/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {getSessionStoragedCurrentOperator, getStoragedCurrentOperator, useAuth} from "../../../../app/modules/auth";
import './MobileNav.css';

const MobileNav = () => {
    const intl = useIntl();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState('');
    const sessionOperator = getSessionStoragedCurrentOperator();
    let operator = getStoragedCurrentOperator();
    let isOperator = operator || sessionOperator;

    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location.pathname]);

    return (
        <>
            {
                isOperator ?
                    <div className='influencer-mobile-menu-wrapper'>
                        <ul className='menu menu-gray-600 menu-hover-primary fw-semibold influencer-mobile-menu'>
                            <li className='menu-item'>
                                <a href='/composer/bet-card/quicktip'
                                   className={`menu-link px-2 ${currentLocation.includes('/quicktip') ? 'active' : ''}`}>
                                    {intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.QUICKTIP'})}
                                </a>
                            </li>
                            <li className='menu-item'>
                                <Link to='composer/bet-card/smart-bet'
                                      className={`menu-link px-2 ${currentLocation.includes('/smart-bet') ? 'active' : ''}`}>
                                    {intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.SMARTBET'})}
                                </Link>
                            </li>
                            <li className='menu-item'>
                                <Link to='/composer/bet-card/superbets'
                                      className={`menu-link px-2 ${currentLocation.includes('/superbets') ? 'active' : ''}`}>
                                    {intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.SUPERBET'})}
                                </Link>
                            </li>
                            <li className='menu-item'>
                                <Link to='/composer/bet-card/quickbet'
                                      className={`menu-link px-2 ${currentLocation.includes('/quickbet') ? 'active' : ''}`}>
                                    {intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.QUICKBET'})}
                                </Link>
                            </li>
                            <li className='menu-item'>
                                <Link to='/composer/bet-card/jackpot'
                                      className={`menu-link px-2 ${currentLocation.includes('/jackpot') ? 'active' : ''}`}>
                                    {intl.formatMessage({id: 'SIDEBAR.SUBNAVIGATION.JACKPOT'})}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    : null
            }
        </>
    )
}

export {MobileNav};
